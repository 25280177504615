import React, { Component } from 'react';
import { Form, Input, Button, Col, Icon, Alert } from "antd";
import i18n from "../../../i18n/i18n";
import { Translation } from 'react-i18next';
import { IRegisterEmailProps, IRegisterEmailState } from '../../models/IRegisterEmail';
import { hasErrors } from '../../share/error';

class RegisterEmailForm extends Component<IRegisterEmailProps, IRegisterEmailState> {
  public static defaultProps: Partial<IRegisterEmailProps> = {
  };

  constructor(props: any) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    // To disable submit button at the beginning.
    this.props.form.validateFields();
  }

  /**
   * @method handleSubmit
   * @param event
   */
  public handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();event.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        this.props.registerEmail(values);
      }
    });
  }
  
  public render() {
    // config fields show error after a field is touched.
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
    //const emailError = isFieldTouched('email') && getFieldError('email');
    const emailError = isFieldTouched('email') && getFieldError('email');

    const registerFormConfig = [
      // email
      {
        rules: [
          {
            required: true,
            message: <Translation>{t => t("mail")}</Translation>
          },
          {
            type: 'email',
             message: i18n.t("translation:email_validate")
          }
        ]
      },
    ];

    return (
      <div>
        <Col className="login-form-fields" span={24}>
          <Form
            layout="vertical"
            className="login-form"
            onSubmit={this.handleSubmit}
          >
            {/**
             * @description show error api reponse
             */}
            { 
              this.props.eMailError &&
                <Alert
                  message={i18n.t("register:registerEmail.error.emailMessage")}
                  type="error"
                />
            }
            <Form.Item
              colon={true}
              label={<Translation>{t => t("mail")}</Translation>}
              wrapperCol={{ span: 24 }}
              hasFeedback
              help={emailError || ''}
              validateStatus={emailError ? 'error' : ''} 
              >
                {
                  getFieldDecorator("email", registerFormConfig[0])(
                    <Input 
                      prefix={
                        <Icon 
                          type="mail" 
                          style={{ color: 'rgba(0,0,0,.25)' }} />} 
                          type="mail" 
                        />
                  )
                }
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                disabled={hasErrors(getFieldsError())}
                >
                {i18n.t("register:registration.button")}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </div>
    );
  }
}

const RegisterEmail = Form.create<IRegisterEmailProps>({ name: 'register_password' })(RegisterEmailForm);

export default RegisterEmail;
