import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { IStateDashboard, IAction } from './../models/dashboard';

/**
 * initial state
 */
const initialState: IStateDashboard = {
  items: [],
  errorConsent: false,
  consentLoading: true,
  error: false,
  enablePatient: false,
  accessGrantingLoading: false,
  accessGrantingError: false,
  newConsentAccessGranting: '',
  newItemConsentItem: {},
  changeCosentStatusChangeLoading: false,
  changeConsentDisable: false,
};

/**
 * @function initDashboard
 * @param state
 * @param action
 */
const initDashboard = (state: IStateDashboard, action: IAction) => {
  return updateObject(state, {
    items: initialState,
    errorConsent: false,
    consentLoading: true,
  });
};

/**
 * @function setDashboard
 * @param state
 * @param action
 */
const setDashboard = (state: IStateDashboard, action: any) => {
  return updateObject(state, {
    items: action.items.map((item: any) => {
      const data_split = item.name.split('/');
      return {
        id: data_split[1],
        name: data_split[0],
        part: item.part,
      };
    }),
    error: false,
    errorConsent: false,
    consentLoading: false,
  });
};

/**
 * @function initDashboardFail
 * @param state
 * @param action
 */
const initDashboardFail = (state: IStateDashboard, action: IAction) => {
  return updateObject(state, {
    errorConsent: true,
    consentLoading: false,
  });
};

/**
 *
 * @param state
 * @param action
 */
const changeConsentItem = (state: IStateDashboard, action: any) => {
  return updateObject(state, {
    changeCosentStatusChangeLoading: true,
  });
};

/**
 *
 * @param state
 * @param action
 */
const changeConsentItemDisable = (state: IStateDashboard, action: any) => {
  return updateObject(state, {
    changeConsentDisable: true,
  });
};

/**
 *
 * @param state
 * @param action
 */
const changeConsentItemSuccess = (state: IStateDashboard, action: any) => {
  // change state
  if (action.item.part[0].valueString === 'INACTIVE') {
    action.item.part[0].valueString = 'ACTIVE';
  } else {
    action.item.part[0].valueString = 'INACTIVE';
  }
  return updateObject(state, {
    changeCosentStatusChangeLoading: true,
    changeConsentDisable: false,
    items: state.items.map((item: any) => {
      return item;
    }),
  });
};

/**
 *
 * @param state
 * @param action
 */
const changeConsentItemFail = (state: any) => {
  return updateObject(state, {
    changeCosentStatusChangeLoading: false,
    changeConsentDisable: false,
  });
};

/**
 *
 * @param state
 * @param action
 */
const enablePatient = (state: IStateDashboard, action: any) => {
  return updateObject(state, {});
};

/**
 *
 * @param state
 * @param action
 */
const enablePatientSuccess = (state: IStateDashboard, action: any) => {
  return updateObject(state, { enablePatient: action.status });
};

/**
 *
 * @param state
 * @param action
 */
const enablePatientFail = (state: IStateDashboard, action: any) => {
  return updateObject(state, { enablePatient: action.status });
};

const createFirstCase = (state: IStateDashboard, action: any) => {
  return updateObject(state, {});
};

/**
 *
 * @param state
 * @param action
 */
const addConsentItemAccessGranting = (
  state: IStateDashboard,
  action: IAction
) => {
  return updateObject(state, { items: action, accessGrantingError: false });
};

/**
 *
 * @param state
 * @param action
 */
const addConsentItemAccessGrantingSuccess = (
  state: IStateDashboard,
  action: any
) => {
  return updateObject(state, {
    accessGrantingLoading: true,
    accessGrantingError: false,
    newConsentAccessGranting: action.newConsentAccessGranting,
    newItemConsentItem: action.item,
  });
};

/**
 *
 * @param state
 * @param action
 */
const addConsentItemAccessGrantingFail = (
  state: IStateDashboard,
  action: any
) => {
  return updateObject(state, {
    accessGrantingLoading: false,
    accessGrantingError: true,
  });
};

/**
 *
 * @param state
 * @param action
 */
const cancelAddConsentItemFinishGranting = (
  state: IStateDashboard,
  action: IAction
) => {
  return updateObject(state, {
    accessGrantingLoading: false,
    accessGrantingError: false,
  });
};

/**
 *
 * @param state
 * @param action
 */
const addConsentItemFinishGranting = (
  state: IStateDashboard,
  action: IAction
) => {
  return updateObject(state, { accessGrantingLoading: false });
};

/**
 *
 * @param state
 * @param action
 */
const addConsentItemFinishGrantingSuccess = (
  state: IStateDashboard,
  action: any
) => {
  // change status because first response get wrong status
  state.newItemConsentItem.part[0].valueString = 'ACTIVE';
  return updateObject(state, {
    error: false,
    accessGrantingLoading: false,
    accessGrantingError: false,
    items: [...state.items, state.newItemConsentItem],
  });
};

/**
 *
 * @param state
 * @param action
 */
const addConsentItemFinishGrantingFail = (
  state: IStateDashboard,
  action: any
) => {
  return updateObject(state, {
    accessGrantingLoading: false,
    accessGrantingError: true,
  });
};

/**
 *
 * @param state
 * @param action
 */
const reducer = (state: IStateDashboard = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.INIT_DASHBOARD:
      return initDashboard(state, action);
    case actionTypes.SET_DASHBOARD:
      return setDashboard(state, action);
    case actionTypes.INIT_DASHBOARD_FAIL:
      return initDashboardFail(state, action);
    case actionTypes.CHANGE_CONSENT_ITEM:
      return changeConsentItem(state, action);
    case actionTypes.CHANGE_CONSENT_ITEM_DISABLE:
      return changeConsentItemDisable(state, action);
    case actionTypes.CHANGE_CONSENT_ITEM_SUCCESS:
      return changeConsentItemSuccess(state, action);
    case actionTypes.CHANGE_CONSENT_ITEM_FAIL:
      return changeConsentItemFail(state);
    case actionTypes.ENABLE_PATIENT:
      return enablePatient(state, action);
    case actionTypes.ENABLE_PATIENT_SUCCESS:
      return enablePatientSuccess(state, action);
    case actionTypes.ENABLE_PATIENT_FAIL:
      return enablePatientFail(state, action);
    case actionTypes.CREATE_FIRST_CASE:
      return createFirstCase(state, action);
    case actionTypes.ADD_CONSENT_ITEM_ACCESS_GRANTING:
      return addConsentItemAccessGranting(state, action);
    case actionTypes.ADD_CONSENT_ITEM_ACCESS_GRANTING_SUCCESS:
      return addConsentItemAccessGrantingSuccess(state, action);
    case actionTypes.ADD_CONSENT_ITEM_ACCESS_GRANTING_FAIL:
      return addConsentItemAccessGrantingFail(state, action);
    case actionTypes.CANCEL_ADD_CONSENT_ITEM_FINISH_GRATING:
      return cancelAddConsentItemFinishGranting(state, action);
    case actionTypes.ADD_CONSENT_ITEM_FINISH_GRANTING:
      return addConsentItemFinishGranting(state, action);
    case actionTypes.ADD_CONSENT_ITEM_FINISH_GRANTING_SUCCESS:
      return addConsentItemFinishGrantingSuccess(state, action);
    case actionTypes.ADD_CONSENT_ITEM_FINISH_GRANTING_FAIL:
      return addConsentItemFinishGrantingFail(state, action);
    default:
      return state;
  }
};

export default reducer;
