import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Result } from 'antd';
import i18n from '../../../i18n/i18n';
import { Translation } from 'react-i18next';
import { hasErrors } from '../../share/error';
import { INewPasswordFormProps } from '../../models/INewPasswordForm';
import './NewPasswordForm.scss';

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
  },
};

const NewPasswordForm: React.FC<any> = (props: INewPasswordFormProps): any => {
  const {
    getFieldDecorator,
    getFieldsError,
    // getFieldError,
    // isFieldTouched,
  } = props.form;
  // Only show error after a field is touched.
  const [confirmDirty, setConfirmDirty] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.form.validateFields((err: any, values: any) => {
      if (!err) {
        props.handleSubmit(values);
      }
    });
  };

  /**
   * @function validateToNextPassword
   * @descrioption
   * @param rule
   * @param value
   * @param callback
   */
  const validateToNextPassword = (rule: any, value: any, callback: any) => {
    const { form } = props;
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  /**
   * @function compareToFirstPassword
   * @description
   * @param rule
   * @param value
   * @param callback
   */
  const compareToFirstPassword = (rule: any, value: any, callback: any) => {
    const { form } = props;
    if (value && value !== form.getFieldValue('password')) {
      callback(
        <Translation>
          {(t) => (
            <span>
              {t('register:registerPassword.validator.newPasswordConfirm')}
            </span>
          )}
        </Translation>
      );
    } else {
      callback();
    }
  };

  const handleConfirmBlur = (e: any) => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  // props.form.validateFields();

  return (
    <>
      {!props.newPasswordSuccess && (
        <>
          <Form
            {...layout}
            layout="vertical"
            autoComplete="on"
            onSubmit={handleSubmit}
          >
            <Form.Item
              label={i18n.t('login:registerPassword.newPassword')}
              hasFeedback
            >
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: i18n.t('login:registerPassword.newPasswordEnter'),
                  },
                  {
                    validator: validateToNextPassword,
                  },
                ],
              })(<Input.Password />)}
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {i18n.t('login:registerPassword.newPasswordRepeat')}
                </span>
              }
              hasFeedback
            >
              {getFieldDecorator('confirm', {
                rules: [
                  {
                    required: true,
                    message: (
                      <Translation>
                        {(t) => t('login:registerPassword.newPasswordRepeat')}
                      </Translation>
                    ),
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              })(<Input.Password onBlur={handleConfirmBlur} />)}
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="reset-password-form-button"
                disabled={hasErrors(getFieldsError())}
              >
                <span>{i18n.t('login:button.submit')}</span>
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
      {props.newPasswordSuccess && (
        <>
          <Result
            status={'success'}
            title={i18n.t('login:resetPassword.success')}
          />
          <p className="saving">
            <Link className="ant-btn ant-btn-primary" to="/signin">
              {i18n.t('login:resetPassword.goTologin')}
            </Link>
          </p>
        </>
      )}
    </>
  );
};

export default NewPasswordForm;
