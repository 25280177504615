import React, { Fragment } from 'react';
import { Col, Row } from "antd";
import i18n from "../../../i18n/i18n";
import { IFinishActivationProps } from '../../models/IFinishActivation';

const FinishActivation: React.FC<any> = (props: IFinishActivationProps) => {
  return (
    <Fragment>
      <Row
        className="finish-activate-wrapper"
        type="flex"
        justify="center"
        align="middle">
        <Col>
          <h1>{i18n.t("pageFinishActivation.description")}</h1>
        </Col>
      </Row>
    </Fragment>
  );
}

export default FinishActivation;
