import React, { Component, Suspense, lazy, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';
import './app.scss';
import claim_logo from './../../../assets/comjoodoc_logo_claim.png';
import sanecumLogo from '../../../assets/sanecum-logo.png';
import HeaderContent from '../Header/HeaderContent';
import FooterContent from '../Footer/FooterContent';
import Login from '../../../core/auth/pages/Login/Login';
import Logout from '../../../core/auth/pages/Logout/Logout';
import Error from '../../../core/auth/pages/Error/Error';
import Register from '../../../core/auth/pages/Register/Register';
import ResetPassword from '../../../core/auth/pages/ResetPassword/ResetPassword';
import NewPassword from '../../../core/auth/pages/NewPassword/NewPassword';

import { connect } from 'react-redux';
import * as actions from './../../../store/actions/index';

// Lazy Loading
const AsyncDashboard = lazy(
  () => import('./../../../module/dashboard/pages/Dashboard/Dashboard')
);
const { Header, Footer } = Layout;

const claimLogos: any = {
  sanecum: {
    logoPath: sanecumLogo,
  },
  comjoo: {
    logoPath: claim_logo,
  },
};

const meta: any = {
  sanecum: {
    title: 'Sanecum Mein Arzt',
    description: 'Sanecum Mein Arzt',
    favicon: `${process.env.PUBLIC_URL}/sanecum-favicon.ico`,
    manifest: `${process.env.PUBLIC_URL}/sanecum-manifest.json`,
  },
  comjoo: {
    title: 'comjoodoc EASY',
    description: 'comjoodoc EASY',
    favicon: `${process.env.PUBLIC_URL}/favicon.ico`,
    manifest: `${process.env.PUBLIC_URL}/manifest.json`,
  },
};

interface Props {
  onTryAutoSignup: any;
  onTryAutoRegisterFormPosition: any;
  logOut: any;
  isAuthenticated: boolean;
  history: any;
}

interface State {}
class App extends Component<Props, State> {
  public static defaultProps: Partial<Props> = {};
  public auth: any = null;

  /**
   * @description init process auth check and register stepform position
   */
  componentDidMount = () => {
    this.props.onTryAutoSignup();
    this.props.onTryAutoRegisterFormPosition();
  };

  /**
   * dispatch logout redux
   */
  logout = () => {
    this.props.logOut();
  };

  /**
   *
   */
  render = () => {
    let routes = (
      <Switch>
        <Route path="/register" exact component={Register}></Route>
        <Route path="/signin" exact component={Login}></Route>
        <Route path="/logout" exact component={Logout}></Route>
        <Route path="/validate-email/:token" component={Error}></Route>
        <Route path="/reset-password" exact component={ResetPassword}></Route>
        <Route path="/new-password/:token" component={NewPassword}></Route>
        <Redirect to="/signin" />
      </Switch>
    );

    // flag trigger guard route Authentication
    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route path="/dashboard" exact component={AsyncDashboard}></Route>
          <Route path="/logout" exact component={Logout}></Route>
          <Route path="/dashboard" component={AsyncDashboard}></Route>
          <Redirect to="/dashboard" />
        </Switch>
      );
    }

    return (
      <Fragment>
        {/*
        Programmatically change meta information based on the selected theme
        */}
        <Helmet>
          <title>{meta[window._env_.THEME_ENV].title}</title>
          <meta
            name="description"
            content={meta[window._env_.THEME_ENV].description}
          />
          <link rel="icon" href={meta[window._env_.THEME_ENV].favicon} />
          <link rel="manifest" href={meta[window._env_.THEME_ENV].manifest} />
        </Helmet>
        <Router>
          <Suspense
            fallback={
              <div
                style={{
                  backgroundColor: 'rgba(238, 238, 238, .3)',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                }}
              >
                <div className="loading-content">
                  {' '}
                  <img
                    style={{ width: '30vw' }}
                    src={claimLogos[window._env_.THEME_ENV].logoPath}
                    alt="Logo"
                  />{' '}
                </div>
              </div>
            }
          >
            <Header
              className="layout-content-header"
              style={{ width: '100%', backgroundColor: '#313237' }}
            >
              <HeaderContent
                onChange={this.logout}
                isAuthenticated={this.props.isAuthenticated}
              />
            </Header>
            <Layout>{routes}</Layout>
            <Footer className="app-footer">
              <FooterContent />
            </Footer>
          </Suspense>
        </Router>
      </Fragment>
    );
  };
}

const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onTryAutoRegisterFormPosition: () => dispatch(actions.registerCheckState()),
    logOut: () => dispatch(actions.logout()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
