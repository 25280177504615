import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

/**
 * initial state
 */
const initialState = {
  token: null,
  userId: null,
  error: null,
  loading: false,
  authRedirectPath: '/',
  loginError: false,
  resetPasswordSuccess: 0,
  newPasswordSuccess: false,
  validateTokenSucess: false,
};

/**
 *
 * @param state
 * @param action
 */
const authStart = (state: any, action: any) => {
  return updateObject(state, { error: null, loading: true, loginError: false });
};

/**
 *
 * @param state
 * @param action
 */
const authSuccess = (state: any, action: any) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    error: null,
    loading: false,
    loginError: false,
  });
};

/**
 *
 * @param state
 * @param action
 */
const authFail = (state: any, action: any) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    loginError: true,
  });
};

/**
 *
 * @param state
 * @param action
 */
const authLogout = (state: any, action: any) => {
  return updateObject(state, { token: null, userId: null });
};

/**
 *
 * @param state
 * @param action
 */
const setAuthRedirectPath = (state: any, action: any) => {
  return updateObject(state, { authRedirectPath: action.path });
};

/**
 *
 * @param state
 * @param action
 */
const resetPassword = (state: any, action: any) => {
  return updateObject(state, {
    resetPasswordSuccess: 0,
  });
};

/**
 *
 * @param state
 * @param action
 */
const resetPasswordSuccess = (state: any, action: any) => {
  return updateObject(state, { resetPasswordSuccess: 1 });
};

/**
 *
 * @param state
 * @param action
 */
const resetPasswordFail = (state: any, action: any) => {
  return updateObject(state, { resetPasswordSuccess: 2 });
};

/**
 *
 * @param state
 * @param action
 */
const setNewPassword = (state: any, action: any) => {
  return updateObject(state, { newPasswordSuccess: false });
};

/**
 *
 * @param state
 * @param action
 */
const setNewPasswordSuccess = (state: any, action: any) => {
  return updateObject(state, { newPasswordSuccess: true });
};

/**
 *
 * @param state
 * @param action
 */
const setNewPasswordFail = (state: any, action: any) => {
  return updateObject(state, { newPasswordSuccess: false });
};

/**
 *
 * @param state
 * @param action
 */
const validateToken = (state: any, action: any) => {
  return updateObject(state, { validateTokenSuccess: false });
};

/**
 *
 * @param state
 * @param action
 */
const validateTokenSuccess = (state: any, action: any) => {
  return updateObject(state, { validateTokenSuccess: true });
};

/**
 *
 * @param state
 * @param action
 */
const validateTokenFail = (state: any, action: any) => {
  return updateObject(state, { validateTokenSuccess: false });
};

/**
 *
 * @param state
 * @param action
 */
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.RESET_PASSWORD:
      return resetPassword(state, action);
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state, action);
    case actionTypes.RESET_PASSWORD_FAIL:
      return resetPasswordFail(state, action);
    case actionTypes.SET_NEW_PASSWORD:
      return setNewPassword(state, action);
    case actionTypes.SET_NEW_PASSWORD_SUCCESS:
      return setNewPasswordSuccess(state, action);
    case actionTypes.SET_NEW_PASSWORD_FAIL:
      return setNewPasswordFail(state, action);
    case actionTypes.VALIDATE_TOKEN:
      return validateToken(state, action);
    case actionTypes.VALIDATE_TOKEN_SUCCESS:
      return validateTokenSuccess(state, action);
    case actionTypes.VALIDATE_TOKEN_FAIL:
      return validateTokenFail(state, action);
    default:
      return state;
  }
};

export default reducer;
