import React, { Fragment } from 'react';
// import { NavLink } from "react-router-dom"; //Link, Redirect
import { Button } from 'antd'; // Icon, Menu
// translation
import { useTranslation } from 'react-i18next';
import LanguageSwitch from './LanguageSwitch';
// import { withRouter } from "react-router";
// UI
import './HeaderContent.scss';
// import menuItems from './nav';
import comjooLogo from './../../../assets/logo-comjoodoc-easy-lang-color-white.png';
import sanecumLogo from './../../../assets/sanecum-logo-white.svg';

const logos: any = {
  sanecum: {
    logoPath: sanecumLogo,
    width: 52,
    title: 'sanecum.title',
  },
  comjoo: {
    logoPath: comjooLogo,
    width: 120,
    title: 'title',
  },
};

interface Props {
  //history:any;
  isAuthenticated: boolean;
  onChange: any;
}

const HeaderContent = (props: Props) => {
  const [t] = useTranslation();
  const styles = {
    lineHeight: '60px',
    backgroundColor: 'transparent',
  };
  /**
   * return section { content } html element

  const [t] = useTranslation();
  const styles = {
    lineHeight: '60px',
    backgroundColor: 'transparent'
  };

  const nav = [
    {
      class: 'nav-link',
      activeClass: 'ant-menu-item-selected',
      src: '/dashboard',
      text: 'Dashboard',
      auth: true
    },
    {
      class: 'nav-link',
      activeClass: 'ant-menu-item-selected',
      src: '/signin',
      text: 'Login',
      auth: false
    },
    {
      class: 'nav-link',
      activeClass: 'ant-menu-item-selected',
      src: '/register',
      text: 'Register',
      auth: true
    }
  ];
  */
  function logout() {
    props.onChange();
  }

  return (
    <Fragment>
      {/* JSON.stringify(props) */}
      <div className="menuuser" style={styles}>
        <LanguageSwitch />
        {props.isAuthenticated ? (
          <Button onClick={logout} type="primary" icon="poweroff">
            <span className="logout-btn">{t('header.btn_logout')}</span>
          </Button>
        ) : null}
      </div>
      <div className="logo">
        <img
          src={logos[window._env_.THEME_ENV].logoPath}
          alt="logo"
          style={{
            width: logos[window._env_.THEME_ENV].width,
            marginTop: '-7px',
          }}
        />
      </div>
      {/**
      <Menu theme="dark" mode="horizontal" style={styles}>
          {
            nav.map((item: any, index) => {
              if(props.isAuthenticated && item.text !== 'Login') {
                if(props.isAuthenticated) {
                  return <Menu.Item key={index}>
                  <NavLink  to={item.src}>{item.text}</NavLink>
                </Menu.Item>
                } else { return null }
              } else {
                if(!props.isAuthenticated) {
                  return <Menu.Item key={index}>
                    <NavLink  to={item.src}>{item.text}</NavLink>
                  </Menu.Item>
                } else { return null }
              }
            })
          }
      </Menu>
     */}
      {/* menuItems <Link to="/logout">logout</Link> */}
    </Fragment>
  );
};

export default HeaderContent;
