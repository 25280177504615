import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Row, Col, Steps, Typography, Alert, Button } from 'antd';
import i18n from './../../../i18n/i18n';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import './Register.scss';
import UserActivate from '../../components/UserActivate/UserActivate';
import RegisterEmail from '../../components/RegisterEmail/RegisterEmail';
import RegisterPassword from '../../components/RegisterPassword/RegisterPassword';
import FinishActivation from '../../components/FinishActivation/FinishActivation';
import RegistrationDoneMessage from '../../components/RegistrationDoneMessage/RegistrationDoneMessage';
import { IRegisterProps, IRegisterState } from './../../models/IRegister';

const { Step } = Steps;
const { Title } = Typography;
class RegisterForm extends Component<IRegisterProps, IRegisterState> {
  //

  public static defaultProps: Partial<IRegisterProps> = {};

  constructor(props: IRegisterProps) {
    super(props);

    this.state = {
      current: 0,
      email: '',
      password: '',
      submitted: false,
      formErrors: {
        email: '',
        password: '',
        error: false,
      },
      emailValid: false,
      passwordValid: false,
      formValid: false,
    };
  }

  changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
  };

  /**
   * @method setComponent
   * @param name
   */
  setComponent(name: string) {
    const props: any = {};
    return React.createElement(UserActivate, props);
  }

  /**
   * @method sendRegister
   */
  sendRegister = (values: any) => {
    this.props.onRegister(values);
  };

  /**
   * @method registerEmail
   * @param values
   */
  registerEmail = (values: any) => {
    this.props.registerEmail(values);
  };

  /**
   * @method registerNewPassword
   * @param credential
   */
  registerNewPassword = (credential: any) => {
    this.props.registerNewPassword(credential, this.props.history);
  };

  render = () => {
    const {
      current,
      userActivateError,
      eMailError,
      setPasswordError,
    } = this.props;
    let steps = [];
    if (window._env_.THEME_ENV === 'comjoo') {
      steps.push(
        {
          title: i18n.t('register:activate'),
          content: (
            <UserActivate
              userActivateError={userActivateError}
              sendRegister={this.sendRegister}
              error={false}
            />
          ),
          iconType: 'user',
          id: '1',
        },
        {
          title: i18n.t('register:stepTitleDone'),
          content: (
            <RegisterEmail
              eMailError={eMailError}
              registerEmail={this.registerEmail}
            />
          ),
          iconType: 'solution',
          id: '2',
        },
        {
          title: i18n.t('register:stepTitleVerification'),
          content: <FinishActivation />,
          iconType: 'check-circle',
          id: '3',
        },
        {
          title: i18n.t('register:stepTitleRegisterPassword'),
          content: (
            <RegisterPassword
              setPasswordError={setPasswordError}
              registerNewPassword={this.registerNewPassword}
              error={false}
            />
          ),
          iconType: 'lock',
          id: '4',
        },
        {
          title: i18n.t('register:done'),
          content: <RegistrationDoneMessage />,
          iconType: 'smile-o',
          id: '5',
        }
      );
    } else {
      steps.push(
        {
          title: i18n.t('register:activate'),
          content: (
            <UserActivate
              userActivateError={userActivateError}
              sendRegister={this.sendRegister}
              error={false}
            />
          ),
          iconType: 'user',
          id: '1',
        },
        {
          title: i18n.t('register:stepTitleRegisterPassword'),
          content: (
            <RegisterPassword
              setPasswordError={setPasswordError}
              registerNewPassword={this.registerNewPassword}
              error={false}
            />
          ),
          iconType: 'lock',
          id: '4',
        },
        {
          title: i18n.t('register:done'),
          content: <RegistrationDoneMessage />,
          iconType: 'smile-o',
          id: '5',
        }
      );
    }

    return (
      <div className="wrapper">
        <Title className="page-titel">{i18n.t('register:page_titles')}</Title>
        {/** TODO REFACTORE CREATE STEPFORM COMPONENT */}

        <Row gutter={[{ xs: 32, sm: 24, md: 24, lg: 24 }, 20]}>
          <Col xs={1} sm={1} md={2} lg={2} xl={2} />
          <Col xs={22} sm={22} md={20} lg={20} xl={20}>
            {/* create stepform header */}
            <Steps size="small" current={current}>
              {steps.map((item) => (
                <Step
                  icon={
                    <Icon
                      type={item.iconType}
                      style={{
                        color:
                          item.iconType === 'close-circle' ? 'gray' : 'null',
                      }}
                    />
                  }
                  key={item.title}
                  title={item.title}
                />
              ))}
            </Steps>
          </Col>
          <Col xs={1} sm={1} md={2} lg={2} xl={2} />
        </Row>

        <Row gutter={[{ xs: 32, sm: 16, md: 8, lg: 2 }, 20]}>
          <Col xs={0} sm={0} md={2} lg={2} xl={2} />
          <Col xs={24} sm={24} md={20} lg={20} xl={20}>
            <Col
              style={{
                background: '#fff',
                boxSizing: 'border-box',
                //padding: '20px',
              }}
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
            >
              <Row>
                <Col xs={0} sm={0} md={2} lg={6} xl={6} />
                <Col xs={24} sm={24} md={20} lg={12} xl={12}>
                  {/* steps content */}
                  <div className="steps-content">{steps[current].content}</div>
                  {/**
                   * @description reset register form
                   */}
                  {current === 1 && (
                    <Button onClick={this.props.registerResetButton}>
                      {i18n.t('register:registration.resetButton')}
                    </Button>
                  )}
                </Col>
                <Col xs={0} sm={0} md={2} lg={6} xl={6} />
              </Row>
              <Col xs={0} sm={0} md={1} lg={1} xl={1} />
              <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                {/**
                 * @description show only alert in first step
                 */}
                {current === 0 && (
                  <Alert
                    message={i18n.t('register:registration.goToLogin')}
                    type="info"
                    description={
                      <Link to="/signin">
                        <span className="login-info">
                          {i18n.t('register:gotoLogin')}
                        </span>
                      </Link>
                    }
                    showIcon
                    closable
                    banner
                  />
                )}
              </Col>
              <Col xs={0} sm={0} md={1} lg={1} xl={1} />
            </Col>
          </Col>
        </Row>
      </div>
    );
  };
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.register.step,
    error: state.register.error,
    current: state.register.stepFormPosition,
    userActivateError: state.register.userActivateError,
    eMailError: state.register.eMailError,
    setPasswordError: state.register.setPasswordError,
    registrationComplete: state.register.registrationComplete,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onRegister: (credential: any) => dispatch(actions.register(credential)),
    registerEmail: (credential: any) =>
      dispatch(actions.registerEmail(credential)),
    registerNewPassword: (credential: any, history: any) =>
      dispatch(actions.registerNewPassword(credential, history)),
    registerResetButton: (credential: any) =>
      dispatch(actions.registerResetButton()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()<any>(RegisterForm));
