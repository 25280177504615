import * as React from "react";
import { Select } from "antd";
import FlageDe from "./../../../assets/flag-de.svg";
import FlageEn from "./../../../assets/flag-en.svg";
import { useTranslation } from "react-i18next";
import './LanguageSwitch.scss';

interface IOptionsSelectLanguage extends Array<any> {
  [index: number]: {
    src: any;
    value: any;
  }
}

const LanguageSwitch = () => {
  const { Option } = Select;
  const { i18n } = useTranslation();
  
  const changeLanguage = (lng:any) => {
    i18n.changeLanguage(lng);
  }

  const options: IOptionsSelectLanguage = [
    {
      src: FlageDe,
      value: 'de',
    },
    {
      src: FlageEn,
      value: 'en',
    }
  ];

  return (
    <Select
      defaultValue={localStorage.getItem('i18nextLng')}
      className="language-menu"
      onChange={changeLanguage}>
      {options.map(item => (
        <Option className="language-item" key={item.value} value={item.value}>
          <img alt="language flag" className="flag-image" src={item.src}/>
        </Option>
      ))}
    </Select>
  );
}
export default LanguageSwitch;
