import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import './FooterContent.scss';
import { Row, Col } from 'antd';
import Logo from './../../../assets/logo-comjoodoc-easy-lang-color-black.svg';
import appleStore from './../../../assets/appstore.png';
import googleStore from './../../../assets/googlestore.png';

const appLinks: any = {
  sanecum: {
    ios:
      'https://apps.apple.com/us/app/sanecum-mein-arzt/id1508288825?l=de&ls=1',
    android:
      'https://play.google.com/store/apps/details?id=com.comjoo.meinarzt',
  },
  comjoo: {
    ios: 'https://apps.apple.com/de/app/comjoodoc-easy/id1485413971',
    android: 'https://play.google.com/store/apps/details?id=com.comjoo.easy',
  },
};

const FooterContent: React.FC = () => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <Row style={{ width: '100%' }}>
        <Col xs={0} sm={0} md={1} lg={1} xl={1} />
        <Col xs={24} sm={0} md={22} lg={22} xl={22} className="footer-content">
          {window._env_.THEME_ENV !== 'sanecum' && (
            <>
              <span className="easy-text"> {t('powerdby')}</span>
              <img alt="Logo" src={Logo} className="footer-logo" />
            </>
          )}
          <span>
            {window._env_.THEME_ENV !== 'sanecum' && (
              <>
                {`${t('comjooService')} `}
                <a className="margin-left-s" href="https://www.comjoodoc.de/">
                  comjoo business solutions GmbH
                </a>
              </>
            )}

            <hr className="footer-divider" />
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={appLinks[window._env_.THEME_ENV].ios}
              style={{ marginLeft: '7px', marginRight: '7px' }}
            >
              <img
                src={appleStore}
                style={{ height: '30px' }}
                alt="Android Store"
              />
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={appLinks[window._env_.THEME_ENV].android}
            >
              <img
                src={googleStore}
                style={{ height: '30px' }}
                alt="Android Store"
              />
            </a>
          </span>
        </Col>
        <Col xs={0} sm={0} md={1} lg={1} xl={1} />
      </Row>
    </Fragment>
  );
};

export default FooterContent;
